<template>
  <div>
    <DataTable
      v-model="selectedRecords"
      :key="selectedFolder.id"
      :loading="loading"
      :headers="headers"
      :can-select="true"
      :drag-and-drop="true"
      :infinite-scroll="true"
      :all-record-ids="allFileIds"
      v-bind="files"
      @infiniteLoad="infiniteLoadFiles"
    >
      <template #toolbar>
        <div class="tw-mb-2 tw-flex tw-justify-between">
          <h3>{{ selectedFolder.name }}</h3>
          <div class="tw-flex tw-flex-wrap tw-gap-y-1 tw-justify-end">
            <button
              type="button"
              title="Publieke bestanden"
              class="action tw-bg-success"
              @click="showAllPublicFiles"
            >
              <i class="fas fa-globe" />
              Publieke bestanden
            </button>
            <button
              v-if="property"
              type="button"
              title="Digitale ondertekensessie starten"
              :disabled="!selectedRecords.length"
              class="action tw-bg-warning"
              @click="startSigningSession(selectedRecords)"
            >
              <i :class="[signingDataLoading ? 'fa fa-spinner -fa-spin' : 'far fa-file-signature']"/> Ondertekenen
            </button>
            <button
              type="button"
              title="Bestanden downloaden"
              :disabled="!selectedRecords.length"
              class="action tw-bg-info"
              @click="downloadMultiple(selectedRecords)"
            >
              <i class="far fa-download"/> Downloaden
            </button>
            <button
              type="button"
              title="Bestanden verplaatsen"
              :disabled="!selectedRecords.length"
              class="action tw-bg-primary"
              @click="showFileMoveModal(selectedRecords, init)"
            >
              <i class="far fa-arrows-alt"/> Verplaatsen
            </button>
            <button
              type="button"
              title="Verwijder bestanden"
              :disabled="!selectedRecords.length"
              class="action tw-bg-danger"
              @click="deleteMultipleFiles(selectedRecords, init)"
            >
              <i class="far fa-trash"/> Verwijder
            </button>
          </div>
        </div>
      </template>

      <template #item-title="{ item }">
        <a v-if="item.url" @click="registerFileAccessed(item)">{{ item.title}}</a>
        <span v-else v-text="item.title" />
        <br>
        <span v-text="item.filename" />
        <span v-if="item.notes"><br><i>Notities: {{ item.notes }}</i></span>
      </template>

      <template #item-type="{ item }">
        <span>{{ item.type }}</span> <span v-if="item.supplier">({{ item.supplier.name }})</span>
      </template>

      <template #item-public="{ item }">
        <input
          v-model="item.public"
          :id="item.id"
          type="checkbox"
          name="public"
          :disabled="item.access_should_be_logged"
          @change="changeFileVisibility(item)"
        />
        <span
          v-if="item.access_should_be_logged"
          title="Dit document is beschermd. Functionaliteit is hierdoor beperkt."
          class="tw-ml-2.5 tw-text-gray-500"
        >
          <i class="fas fa-info-circle" />
        </span>
      </template>

      <template #item-actions="{ item }">
        <div class="tw-flex">
          <button
            type="button"
            title="Bewerk"
            class="action tw-bg-warning"
            @click="showFileModal(item, init)"
          >
            <i class="far fa-pencil" />
          </button>
          <button
            type="button"
            title="Bestanden verplaatsen"
            class="action tw-bg-primary"
            @click="showFileMoveModal([item.id], init)"
          >
            <i class="far fa-arrows"/>
          </button>
          <button
            type="button"
            title="Download"
            class="action tw-bg-info"
            @click="downloadDocument(item)"
          >
            <i class="far fa-download"/>
          </button>
          <button
            type="button"
            title="Email"
            :disabled="!item.source_id || item.access_should_be_logged"
            class="action tw-bg-success"
            @click="mailDocument(item)"
          >
            <i class="far fa-envelope"/>
          </button>
          <button
            type="button"
            title="Verwijder"
            class="action tw-bg-error"
            @click="deleteDocument(item, init)"
          >
            <i class="far fa-trash-alt"/>
          </button>
        </div>
        <span v-if="asbestosFiles.includes(item.id)" class="input-error">
          Dit is een asbestdocument
        </span>
      </template>
    </DataTable>

    <SignatureModal v-if="property" ref="signatureModal" :property="property" />
    <EntityFileModal ref="fileModal" :file-types="fileTypes" @edit="editDocument" />

    <FormulateForm
      #default="{ isLoading }"
      v-model="fileMoveForm"
      name="fileMoveForm"
      @submit="moveToFolder"
    >
      <Modal ref="fileMoveModal" title="Bestand Verplaatsen" size="xs">
        <FormulateInput
          name="targetFolderId"
          type="select"
          label="Map"
          :options="folderOptions"
        />
        <template #footer>
          <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
            <FormulateInput
              type="submit"
              :disabled="isLoading"
              :input-class="['tw-bg-warning tw-w-full']"
              :outer-class="['tw-w-full md:tw-w-auto']"
            >
              <i
                :class="[
                  'fas tw-mr-2',
                  isLoading ? 'fa-spinner-third fa-spin' : 'fa-exchange'
                ]"
              />
              Verplaatsen
            </FormulateInput>
            <FormulateInput
              type="button"
              :input-class="['tw-bg-error tw-w-full']"
              :outer-class="['tw-w-full md:tw-w-auto']"
              @click="hideFileMoveModal"
            >
              <i class="far fa-times tw-mr-2" /> Annuleren
            </FormulateInput>
          </div>
        </template>
      </Modal>
    </FormulateForm>

    <BaseModal
      ref="allPublicFilesModal"
      title="Alle openbare bestanden"
      max-width="tw-max-w-7xl"
    >
      <DataTable
        v-model="selectedFromAllPublicRecords"
        :loading="allPublicFilesLoading"
        :headers="allPublicFilesHeaders"
        :can-select="true"
        :infinite-scroll="true"
        :all-record-ids="allPublicFileIds"
        v-bind="allPublicFiles"
        class="tw--mx-5 tw-shadow-none"
        @infiniteLoad="infiniteLoadAllPublicFiles"
      >
        <template #toolbar>
          <div class="tw-flex tw-flex-wrap tw-gap-y-1 tw-justify-end">
            <button
              v-if="property"
              type="button"
              title="Digitale ondertekensessie starten"
              :disabled="!selectedFromAllPublicRecords.length"
              class="action tw-bg-warning"
              @click="startSigningSession(selectedFromAllPublicRecords)"
            >
              <i :class="[signingDataLoading ? 'fa fa-spinner -fa-spin' : 'far fa-file-signature']"/> Ondertekenen
            </button>
            <button
              type="button"
              title="Bestanden downloaden"
              :disabled="!selectedFromAllPublicRecords.length"
              class="action tw-bg-info"
              @click="downloadMultiple(selectedFromAllPublicRecords)"
            >
              <i class="far fa-download"/> Downloaden
            </button>
            <button
              type="button"
              title="Bestanden verplaatsen"
              :disabled="!selectedFromAllPublicRecords.length"
              class="action tw-bg-primary"
              @click="showFileMoveModal(selectedFromAllPublicRecords, initAllPublicFiles)"
            >
              <i class="far fa-arrows-alt"/> Verplaatsen
            </button>
            <button
              type="button"
              title="Verwijder bestanden"
              :disabled="!selectedFromAllPublicRecords.length"
              class="action tw-bg-danger"
              @click="deleteMultipleFiles(selectedFromAllPublicRecords, initAllPublicFiles)"
            >
              <i class="far fa-trash"/> Verwijder
            </button>
          </div>
        </template>

        <template #item-title="{ item }">
          <a v-if="item.url" @click="registerFileAccessed(item)">{{ item.title}}</a>
          <span v-else v-text="item.title" />
          <br>
          <span v-text="item.filename" />
        </template>

        <template #item-type="{ item }">
          <span>{{ item.type }}</span> <span v-if="item.supplier">({{ item.supplier.name }})</span>
        </template>

        <template #item-public="{ item }">
          <input
            v-model="item.public"
            :id="item.id"
            type="checkbox"
            name="public"
            :disabled="item.access_should_be_logged"
            @change="changeFileVisibility(item)"
          />
          <span
            v-if="item.access_should_be_logged"
            title="Dit document is beschermd. Functionaliteit is hierdoor beperkt."
            class="tw-ml-2.5 tw-text-gray-500"
          >
            <i class="fas fa-info-circle" />
          </span>
        </template>

        <template #item-actions="{ item }">
          <div class="tw-flex">
            <button
              type="button"
              title="Bewerk"
              class="action tw-bg-warning"
              @click="showFileModal(item, initAllPublicFiles)"
            >
              <i class="far fa-pencil" />
            </button>
            <button
              type="button"
              title="Bestanden verplaatsen"
              class="action tw-bg-primary"
              @click="showFileMoveModal([item.id], initAllPublicFiles)"
            >
              <i class="far fa-arrows"/>
            </button>
            <button
              type="button"
              title="Download"
              class="action tw-bg-info"
              @click="downloadDocument(item)"
            >
              <i class="far fa-download"/>
            </button>
            <button
              type="button"
              title="Email"
              :disabled="!item.source_id || item.access_should_be_logged"
              class="action tw-bg-success"
              @click="mailDocument(item)"
            >
              <i class="far fa-envelope"/>
            </button>
            <button
              type="button"
              title="Verwijder"
              class="action tw-bg-error"
              @click="deleteDocument(item, initAllPublicFiles)"
            >
              <i class="far fa-trash-alt"/>
            </button>
          </div>
          <span v-if="asbestosFiles.includes(item.id)" class="input-error">
            Dit is een asbestdocument
          </span>
        </template>
      </DataTable>
    </BaseModal>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import EntityFileModal from '@/components/properties/EntityFileModal'
import SignatureModal from '@/components/SignatureModal'
import EventBus from '@/components/iam/bus'
import Modal from '@/components/iam/Modal'

import { startLoadingModal, stopLoadingModal, questionModal, successModal, errorModal } from '@/modalMessages'
import {
  getPropertyFiles,
  getPropertyFilesAllIds,
  updatePropertyFile,
  deletePropertyFile,
  deletePropertyFilesMultiple,
  downloadPropertyFiles,
  pollPropertyDownload,
  addPropertyFilesToFolder,
  removePropertyFilesFromFolder,
  getPropertyFileAsTemplate,
  propertyFilesDirectDownload,
  getPropertyFilesFromIds,
  registerEntityFilesAccessed
} from '@/services/properties'
import {
  getProjectFiles,
  getProjectFilesAllIds,
  updateProjectFile,
  deleteProjectFile,
  deleteProjectFilesMultiple,
  downloadProjectFiles,
  pollProjectDownload,
  addProjectFilesToFolder,
  removeProjectFilesFromFolder,
  getProjectFileAsTemplate,
  projectFilesDirectDownload,
  getProjectFilesFromIds
} from '@/services/projects'

export default {
  name: 'EntityFileTable',
  components: {
    DataTable,
    EntityFileModal,
    SignatureModal,
    Modal
  },
  props: {
    selectedFolder: {
      type: Object
    },
    project: {
      type: Object,
      default: null
    },
    property: {
      type: Object,
      default: null
    },
    folders: {
      type: Array
    },
    fileTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      allPublicFilesLoading: false,
      // The object files includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      files: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      allPublicFiles: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      allFileIds: [],
      allPublicFileIds: [],
      selectedRecords: [],
      folderOptions: [],
      fileMoveForm: {
        file_id_list: []
      },
      entityFileTypes: [],
      entityFileTypesLoading: false,
      signingDataLoading: false,
      showPublicFilesOnly: false,
      asbestosFiles: [],
      selectedFromAllPublicRecords: [],
      selectedInitMethod: null
    }
  },
  watch: {
    'selectedFolder.id': {
      immediate: true,
      handler () {
        this.init()
      }
    }
  },
  computed: {
    headers () {
      return [
        { value: 'title', text: 'Bestand' },
        { value: 'type', text: 'Type' },
        { value: 'public', text: 'Publiek' },
        { value: 'actions', text: 'Actie' }
      ]
    },
    allPublicFilesHeaders () {
      return [
        { value: 'title', text: 'Bestand' },
        { value: 'folder', text: 'Map' },
        { value: 'type', text: 'Type' },
        { value: 'public', text: 'Publiek' },
        { value: 'actions', text: 'Actie' }
      ]
    }
  },
  created () {
    EventBus.$on('item-dropped-on-node', (fileId, targetFolderId) => {
      return this.moveToFolder({ file_id_list: [fileId], targetFolderId })
    })
  },
  methods: {
    showFileMoveModal (file_id_list, init) {
      this.fileMoveForm.file_id_list = [...file_id_list]
      const parsedChildren = this.folders.map(child => {
        return {
          label: child.name,
          value: child.id
        }
      })
      this.folderOptions = [
        {
          label: 'Bestanden',
          value: ''
        },
        ...parsedChildren
      ]
      this.selectedInitMethod = init
      this.$refs.fileMoveModal.show()
    },
    hideFileMoveModal () {
      this.$refs.fileMoveModal.hide()
    },
    showAllPublicFiles () {
      this.initAllPublicFiles()
      this.$refs.allPublicFilesModal.show()
    },

    async init () {
      // `folder_id=0`is a special filter used by the files endpoint (`/api/v3/property/:id/files` or `/api/v3/project/:id/files`).
      // It is used to notify the backend to return only folder-less files.
      // Not providing any filter returns all the files.
      const params = { folder_id: this.selectedFolder.id }
      if (this.showPublicFilesOnly) params.public = true
      const payload = { params }
      if (this.property) payload.propertyId = this.property.id
      if (this.project) payload.projectId = this.project.id
      return await Promise.all([this.loadFiles(payload), this.loadAllFileIds(payload)])
    },
    async initAllPublicFiles () {
      const payload = { params: { public: true } }
      if (this.property) payload.propertyId = this.property.id
      if (this.project) payload.projectId = this.project.id

      return Promise.all([
        this.loadAllPublicFiles(payload),
        this.loadAllPublicFileIds(payload)
      ])
    },

    async fetchFiles (payload) {
      // We need a driver function to supply payload since it can change due to pagination's next/previous url
      const response = this.property
        ? await getPropertyFiles(payload)
        : await getProjectFiles(payload)
      return response.data
    },
    async loadAllPublicFiles (payload) {
      try {
        this.allPublicFilesLoading = true
        const files = await this.fetchFiles(payload)
        this.allPublicFiles = files
        return files
      } catch (error) {
        errorModal('Fout bij het laden van bestanden, probeer het opnieuw.')
      } finally {
        this.allPublicFilesLoading = false
      }
    },
    async loadFiles (payload) {
      try {
        this.loading = true
        const files = await this.fetchFiles(payload)
        this.files = files
        this.loading = false
        return files
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van bestanden, probeer het opnieuw.')
      }
    },

    async fetchAllFileIds (payload) {
      const response = this.property
        ? await getPropertyFilesAllIds(payload)
        : await getProjectFilesAllIds(payload)
      return response.data
    },
    async loadAllFileIds (payload) {
      try {
        const fileIds = await this.fetchAllFileIds(payload)
        this.allFileIds = fileIds
        return fileIds
      } catch (error) {
        console.error(error)
        errorModal('Er is iets misgegaan, selecteer alle functionaliteit werkt mogelijk niet. Probeer het opnieuw.')
      }
    },
    async loadAllPublicFileIds (payload) {
      try {
        const fileIds = await this.fetchAllFileIds(payload)
        this.allPublicFileIds = fileIds
        return fileIds
      } catch (error) {
        console.error(error)
        errorModal('Er is iets misgegaan, selecteer alle functionaliteit werkt mogelijk niet. Probeer het opnieuw.')
      }
    },

    async infiniteLoad ($infinite, next, filesKey) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this[filesKey].results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const files = await this.fetchFiles({ url: next })
        const results = [...this[filesKey].results, ...files.results]
        this[filesKey] = { ...files, results }
        $infinite.loaded()
        return files
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    infiniteLoadFiles ($infinite, next) {
      return this.infiniteLoad($infinite, next, 'files')
    },
    infiniteLoadAllPublicFiles ($infinite, next) {
      return this.infiniteLoad($infinite, next, 'allPublicFiles')
    },

    downloadDocument (record) {
      let win
      if (!record.source_id) {
        win = window.open(record.url, '_blank')
      } else {
        if (this.property) win = propertyFilesDirectDownload(this.property.id, record.id)
        if (this.project) win = projectFilesDirectDownload(this.project.id, record.id)
      }
      if (!win) errorModal('Sta pop-ups toe om het eigenaarsrapport te zien.')
      return registerEntityFilesAccessed(this.property ? 'property' : 'project', [record.id])
    },
    async deleteDocument (record, init) {
      try {
        this.asbestosFiles = []
        const result = await questionModal('Ben je zeker dat je dit document wilt verwijderen?')
        if (!result.value) return

        startLoadingModal('Document aan het verwijderen')
        if (this.property) await deletePropertyFile(this.property.id, record.id)
        else if (this.project) await deleteProjectFile(this.project.id, record.id)
        successModal(`Het document '${record.title}' is verwijderd!`)
        return await init()
      } catch (error) {
        let message = 'Kan document niet verwijderen, gelieve opnieuw te proberen.'
        if (error.response?.data?.error_code === 'CANNOT_DELETE_ASBESTOS_FILE') {
          message = 'Dit bestand kan niet verwijderd worden omdat het deel uitmaakt van een asbestdossier.'
          this.asbestosFiles = error.response?.data?.asbestos_files
        }
        errorModal(message)
        throw error
      }
    },
    async editDocument (record) {
      try {
        startLoadingModal('Document opslaan...')
        if (this.property) await updatePropertyFile(this.property.id, record)
        else if (this.project) await updateProjectFile(this.project.id, record)
        this.$refs.fileModal.hide()
        successModal('Het document werd successvol aangepast')
        return await this.selectedInitMethod()
      } catch (error) {
        let errorMessage = 'Er ging iets mis bij het aanpassen van het document'
        if (error.response?.data?.code?.[0] === 'FILENAME_ALREADY_EXISTS') errorMessage = 'Er bestaat al een bestand met deze bestandsnaam'
        errorModal(errorMessage)
      }
    },
    async mailDocument (record) {
      startLoadingModal('We plaatsen jouw document in de mailclient...')
      let response
      if (this.property) response = await getPropertyFileAsTemplate(this.property.id, record.id)
      if (this.project) response = await getProjectFileAsTemplate(this.project.id, record.id)

      const documentData = response.data
      const contacts = documentData.recipient ? [{ type: 'to', contact: documentData.recipient }] : []

      EventBus.$emit('mailclient-prefill-and-show', {
        contacts: contacts,
        context: { properties: [this.property] },
        body: documentData.file
      })
      stopLoadingModal()
    },
    getSelectedRecords () {
      return this.selectedRecords
    },
    async showFileModal (file, init) {
      try {
        this.$refs.fileModal.publicFile = file.public
        this.$refs.fileModal.type = file.type_id
        this.$refs.fileModal.file = JSON.parse(JSON.stringify(file))
        this.$refs.fileModal.show()
        this.selectedInitMethod = init
      } catch {
        errorModal('Bestandstypes konden niet geladen worden.')
      }
    },
    async getFilesFromIds (file_ids) {
      let response
      if (this.property) response = await getPropertyFilesFromIds(this.property.id, { file_ids })
      if (this.project) response = await getProjectFilesFromIds(this.project.id, { file_ids })
      return response?.data
    },
    async startSigningSession (fileIds) {
      try {
        if (fileIds.length > 15) return errorModal('Maximaal 15 bestanden per digitale ondertekensessie')
        this.signingDataLoading = true
        const files = await this.getFilesFromIds(fileIds)
        this.$refs.signatureModal.documents = files.map(file => {
          // DEW-6362 Automatically set merge to false if the document is a Google drive document
          return { merge: !file.source_id, ...file }
        })
        this.$refs.signatureModal.show()
        return files
      } catch (error) {
        errorModal('Fout bij het starten van de ondertekensessie, probeer het opnieuw.')
      } finally {
        this.signingDataLoading = false
      }
    },
    async downloadMultiple (files_id_list) {
      try {
        startLoadingModal('Downloaden genereren...')
        let response
        if (this.property) response = await downloadPropertyFiles(this.property.id, { files_id_list })
        else if (this.project) response = await downloadProjectFiles(this.project.id, { files_id_list })
        const jobId = response?.data?.job_id

        const job = setInterval(async () => {
          let response
          if (this.property) response = await pollPropertyDownload(this.property.id, jobId)
          else if (this.project) response = await pollProjectDownload(this.project.id, jobId)
          const status = response?.data?.status

          if (status === 1) {
            clearInterval(job)
            const url = response?.data?.url
            const win = window.open(url, '_blank')
            if (!win) errorModal('Sta pop-ups toe om het eigenaarsrapport te zien.')
            else successModal('Je download is klaar')
            return url
          }

          if (status === 2) {
            // Errors cannot be thrown and caught by the try/catch from a setInterval as
            // try...catch works synchronously and setInterval function will be executed later
            clearInterval(job)
            console.error('Failed to generate the download archive')
            errorModal('Kan de download niet genereren, probeer het opnieuw.')
          }
        }, 300)
        await registerEntityFilesAccessed(this.property ? 'property' : 'project', files_id_list)
        return job
      } catch (error) {
        console.error(error)
        errorModal('Kan de download niet genereren, probeer het opnieuw.')
      }
    },
    removeFilesFromFolder (sourceFolderId, payload) {
      if (this.property) return removePropertyFilesFromFolder(this.property.id, sourceFolderId, payload)
      else if (this.project) return removeProjectFilesFromFolder(this.project.id, sourceFolderId, payload)
      throw new Error('Removing files: No property/project found')
    },
    addFilesToFolder (targetFolderId, payload) {
      if (this.property) return addPropertyFilesToFolder(this.property.id, targetFolderId, payload)
      else if (this.project) return addProjectFilesToFolder(this.project.id, targetFolderId, payload)
      throw new Error('Adding files: No property/project found')
    },
    async moveToFolder ({ file_id_list, targetFolderId }) {
      try {
        startLoadingModal('Bestanden naar map verplaatsen...')
        const sourceFolderId = this.selectedFolder.id
        // If at root folder, we cannot remove files from there.
        if (sourceFolderId && sourceFolderId !== 'unset') await this.removeFilesFromFolder(sourceFolderId, { file_id_list })
        // If target is root folder, we don't need to add files back to it, it'll automatically get there after removing from source.
        if (targetFolderId && targetFolderId !== 'unset') await this.addFilesToFolder(targetFolderId, { file_id_list })
        const response = await this.selectedInitMethod()
        this.hideFileMoveModal()
        successModal('Bestanden zijn succesvol verplaatst')
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het verplaatsen van bestanden, probeer het opnieuw.')
      }
    },
    async changeFileVisibility ({ id, filename, ...file }) {
      try {
        // Public is a reserved word, we cannot do assignment to it as a constant.
        // The value of item's public field is modified by the checkbox, as it is v-modeled.
        const payload = { id, public: file.public }
        const response = this.property
          ? await updatePropertyFile(this.property.id, payload)
          : await updateProjectFile(this.project.id, payload)
        successModal(`${filename} is nu ${file.public ? 'publiek' : 'privé'}.`)
        return response
      } catch (error) {
        console.error(error)
        errorModal(`Kan de zichtbaarheid van ${filename} niet wijzigen, probeer het opnieuw.`, true)
      }
    },
    async deleteMultipleFiles (file_id_list, init) {
      try {
        this.asbestosFiles = []
        const result = await questionModal(`Weet je zeker dat je de geselecteerde bestanden wilt verwijderen? Er zullen <b>${file_id_list.length} bestanden</b> verwijderd worden.`)
        if (!result.value) return

        startLoadingModal('Bestanden verwijderen')
        if (this.property) await deletePropertyFilesMultiple(this.property.id, { file_id_list })
        else if (this.project) await deleteProjectFilesMultiple(this.project.id, { file_id_list })
        successModal('De bestanden zijn verwijderd')
        return await init()
      } catch (error) {
        let message = 'Kan de geselecteerde bestanden niet verwijderen, probeer het opnieuw.'
        if (error.response?.data?.error_code === 'CANNOT_DELETE_ASBESTOS_FILE') {
          message = 'Je kunt geen bestanden verwijderen van het deel bestaande uit een asbestdossier.'
          this.asbestosFiles = error.response?.data?.asbestos_files
        }
        errorModal(message)
        throw error
      }
    },
    async registerFileAccessed (file) {
      const response = await registerEntityFilesAccessed(this.property ? 'property' : 'project', [file.id])
      window.open(file.url, '_blank')
      return response
    }
  }
}
</script>
